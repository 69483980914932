<template>
  <b-row class="d-flex justify-content-center aligns-center min-vh-100" align-v="center">
    <b-col sm="10" md="4" lg="4" xl="4" xxl="4">
      <div class="d-flex justify-content-center mb-4">
        <img style="max-height: 58px;" src="assets/images/logo-width-purple.png" alt="">
      </div>
      <b-card>
        <div class="flex-between-center p-4">
          <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
              <h5>Log in</h5>
              <div class="mt-3 w-100"></div>
              <form-group-input placeholder="Email anda" :need-label="false" label="Email" v-model="model.username" rules="required|email" id="username" />
              <div class="mt-3 w-100"></div>
              <form-group-input placeholder="Password anda" :need-label="false" label="Email" v-model="model.password" rules="required" type="password" id="password" />
              <!-- <b-row class="flex-between-center">
                <b-col cols="auto">
                  <a class="fs--1" href="#">Forgot Password?</a>
                </b-col>
              </b-row> -->
              <div>
                <b-button type="submit" variant="primary" block class="w-100 mt-3"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Log in</b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import FormGroupInput from '@/components/FormGroupInput.vue';
export default {
  components: {
    FormGroupInput
  },
  data () {
    return {
      isLoading: false,
      model: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      this.$store.dispatch('account/login', {
        username: this.model.username,
        password: this.model.password
      }).then(() => {
        // this.$store.dispatch('notification/welcome', this.$store.getters['account/accountData'].firstName)
        this.isLoading = false
        if (this.$store.getters['account/accountData'].role == 'customer service') return this.$router.push('/welcome')
        this.$router.push(this.$route.query.redirect !== undefined ? this.$route.query.redirect : '/').then(() => {
          // location.reload()
        })
      }).catch(() => {
        this.isLoading = false
        // this.$store.dispatch('notification/error', error)
        this.$store.dispatch('notification/error', 'Kombinasi email & password anda salah')
      })
    }
  }
}
</script>